<template>
    <div class="default-bg">
        <section class="container p-3 rounded pb-4">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pt-1 pb-2">
                        <skeleton-box height="20px" width="100%"/>
                    </div>
                    <div class="pt-1 pb-1">
                        <skeleton-box height="100px" width="100%"/>
                    </div>
                </div>
			</div>
            <div class="row justify-content-center mb-4 mt-4">
                <div class="col-md-7 col-12">
                    <skeleton-box height="80px" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="370px" width="100%" />
                    </div>
                    <div class="pt-1 pb-1 mb-3">
                        <skeleton-box height="50px" width="100%" />
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <skeleton-box height="60px" width="100%" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <skeleton-box height="200px" width="100%" />
                </div>
            </div>
            <div class="row mt-5 mb-5">
                <div class="col-7">
                    <skeleton-box height="70px" width="100%" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="row mb-4">
                        <div class="col-md-10 col-12">
                            <skeleton-box height="70px" width="100%" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-12">
                            <skeleton-box height="30px" width="100%" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-lg-6 col-md-12 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                        <div class="col-md-12 mb-2">
                            <skeleton-box height="150px" width="100%" />
                        </div>
                        <div class="col-md-12 mb-2">
                            <skeleton-box height="80px" width="50%" />
                        </div>
                        <div class="col-md-7 col-12 mb-2">
                            <skeleton-box height="20px" width="100%" />
                        </div>
                        <div class="col-md-6 mb-2">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
    components: {
        SkeletonBox
    },
}
</script>