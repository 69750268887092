<template>
	<div class="countycategories">
		<div class="container">
			<div class="title">{{ county.categories_title }}</div>
			<div class="row">
				<div class="col-lg-4 col-md-6 col-12" v-for="category in categories" :key="category.id">
					<router-link :to="'/produse/' + category.slug">
						<div class="categcard">
							<div class="img" :class="category.image ? 'pb-mobile-0' : ''"><img v-if="category.image" :src="category.image" :alt="category.name + ' ' + county.county_name"></div>
							<div class="categ_title"> {{category.name}} </div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "CountyCategories",
	props: ['county', 'categories'],
}

</script>

<style>
.countycategories {
	background-image: url("~@/assets/images/acoperire_nationala_background.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom;
	padding-bottom: 100px;
}

.countycategories .title {
	font-family: Raleway Bold;
	font-size: 44px;
	text-align: center;
	width: 60%;
	margin: 0 auto 50px auto;
	line-height: 0.86;
}

.countycategories .categcard {
	background-color: transparent;
	margin-bottom: 20px;
}

.countycategories .categcard .img {
	background-color: #bebebe;
	width: 330px;
	height: 330px;
	margin: 30px auto;
}

.countycategories .categcard .img img {
	width: 330px;
	height: 330px;
}

.countycategories .categcard .categ_title {
	font-family: Raleway Semibold;
	font-size: 28px;
	color: #2e2e30;
    line-height: normal;
	text-align: center;
	padding: 0 20px;
}

@media (max-width: 1200px) {
	.countycategories .categcard .img, .countycategories .categcard .img img {
		width: 290px;
		height: 290px;
	}
	.countycategories .title {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.countycategories .categcard .img {
		width: 100%;
		padding-bottom: 100%;
	}
	.countycategories .categcard .img img {
		width: auto;
		height: 510px;
	}
}

@media (max-width: 576px) {
	.countycategories {
		padding-bottom: 30px;
	}
	.countycategories .title {
		font-size: 30px;
		margin-bottom: 10px;
	}
	.countycategories .categcard .img, .countycategories .categcard .img img {
		width: 100%;
		height: 100%;
	}
	.pb-mobile-0 {
		padding-bottom: 0 !important;
	}
}
</style>